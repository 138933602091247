import type { ReactNode } from 'react';
import type { SidebarElementInterface } from '../../interfaces/sidebarElement';

import React, { memo, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { SidebarButton, SidebarContent, SidebarMenu } from './SidebarStyle';
import SidebarElement from './SidebarElement/SidebarElement';
import { contactPageAddress } from '../../utils/contactPageAddress';
import { HELPLINE_CONTACT } from '../../constants/commonConstants';
import { openChatbot } from '../../utils/chatbot';
import { isSidebarOptionAvailable } from '../../constants/sidebarOptions';

import EmailIcon from '../../static/images/menu/email.svg';
import PhoneIcon from '../../static/images/menu/phone.svg';
import ArrowIcon from '../../static/images/menu/arrow.svg';
import MessageIcon from '../../static/images/menu/message.svg';

const Sidebar = () => {
    const { t, language } = useI18next();
    const [menuOpen, setMenuOpen] = useState(true);

    const SIDEBAR_ELEMENTS: Array<SidebarElementInterface> = [
        {
            key: 'email',
            icon: EmailIcon,
            title: t('contactUs'),
            href: contactPageAddress(language),
            visible: isSidebarOptionAvailable('email', language),
        },
        {
            key: 'phone',
            icon: PhoneIcon,
            title: t('callUs'),
            hoverTitle: `${HELPLINE_CONTACT}`,
            href: `tel:${HELPLINE_CONTACT.replace(/[ -]/g, '')}`,
            visible: isSidebarOptionAvailable('phone', language),
        },
        {
            key: 'chatbot',
            icon: MessageIcon,
            title: t('askUs'),
            onClick: openChatbot,
            visible: false,
            // visible: isSidebarOptionAvailable('chatbot', language),
        },
    ];

    const toggleMenu = (): void => {
        setMenuOpen(!menuOpen);
    };

    const sidebarElements: SidebarElementInterface[] = SIDEBAR_ELEMENTS.filter((element) => element?.visible);

    const titleLength: number = sidebarElements.reduce(
        (maxLength, element) => Math.max(maxLength, element.title.length),
        0
    );

    const sidebarElementWidth: number = 6 * titleLength + 150;

    const renderSidebarElements = (): ReactNode =>
        SIDEBAR_ELEMENTS?.map((element) => (
            <SidebarElement expanded={menuOpen} setExpanded={setMenuOpen} width={sidebarElementWidth} {...element} />
        ));

    return sidebarElements?.length ? (
        <SidebarMenu>
            <SidebarContent>
                {renderSidebarElements()}
                <SidebarButton src={ArrowIcon} expanded={menuOpen} onClick={toggleMenu} />
            </SidebarContent>
        </SidebarMenu>
    ) : null;
};

export default memo(Sidebar);
