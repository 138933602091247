import { EN, DE, DE_AT, FR, ES } from './languageCode';

const SIDEBAR_OPTIONS = {
    email: [EN, DE, DE_AT, FR, ES],
    phone: [EN, DE, FR, ES],
    chatbot: [DE, DE_AT],
};

type SidebarOption = keyof typeof SIDEBAR_OPTIONS;

export const isSidebarOptionAvailable = (option: SidebarOption, language: string): boolean =>
    !!SIDEBAR_OPTIONS?.[option]?.includes(language);
