import { withPrefix } from 'gatsby';
import { CHATBOT_ENABLED } from '../constants/localStorageKeys';
import { isHomepage } from './isHomepage';
import { isSidebarOptionAvailable } from '../constants/sidebarOptions';

const CHATBOT_SCRIPT_URL = '/chatbot/cobuddy-lite.js';
const CHATBOT_STYLESHEET_URL = '/chatbot/cobuddy.css';
const CHATBOT_ASSETS_URL = '/chatbot/assets/';

const getChatbotScriptElement = (): HTMLScriptElement =>
    document.querySelector(`script[src*="${CHATBOT_SCRIPT_URL}"]`) as HTMLScriptElement;

const getChatbotStyleSheetElement = (): HTMLLinkElement =>
    document.querySelector(`link[href*="${CHATBOT_STYLESHEET_URL}"]`) as HTMLLinkElement;

const getChatbotHtmlElement = (): HTMLElement => document.querySelector('app-root') as HTMLElement;

const injectChatbotStyleSheet = (): boolean => {
    if (!getChatbotStyleSheetElement()) {
        const stylesheet = document.createElement('link');
        stylesheet.setAttribute('rel', 'stylesheet');
        stylesheet.setAttribute('type', 'text/css');
        stylesheet.setAttribute('href', withPrefix(CHATBOT_STYLESHEET_URL));
        document.head.appendChild(stylesheet);
        return true;
    }
    return false;
};

const injectChatbotScript = (): boolean => {
    if (!getChatbotScriptElement()) {
        const scriptElement = document.createElement('script');
        scriptElement.setAttribute('src', withPrefix(CHATBOT_SCRIPT_URL));
        document.body.appendChild(scriptElement);
        return true;
    }
    return false;
};

const handleChatbotCloseAction = (): void => {
    const observer = new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                const deleteButton = document.querySelector('.delete');
                if (deleteButton) {
                    deleteButton.addEventListener('click', closeChatbot);
                    observer.disconnect();
                    break;
                }
            }
        }
    });
    observer.observe(document.body, { childList: true, subtree: true });
};

const injectChatbotHtmlElement = (): boolean => {
    if (!getChatbotHtmlElement()) {
        document.body.insertAdjacentHTML('beforeend', `<app-root assetsFilePath="${CHATBOT_ASSETS_URL}"/>`);
        return true;
    }
    return false;
};

export const injectChatbot = (): void => {
    injectChatbotStyleSheet();
    injectChatbotScript();
    handleChatbotCloseAction();
    injectChatbotHtmlElement();
};

export const removeChatbot = (): void => {
    const scriptElement = getChatbotScriptElement();
    if (scriptElement) {
        scriptElement.remove();
    }

    const styleElement = getChatbotStyleSheetElement();
    if (styleElement) {
        styleElement.remove();
    }

    const chatbotElement = getChatbotHtmlElement();
    if (chatbotElement) {
        chatbotElement.remove();
    }
};

const startChatbot = (): void => {
    removeChatbot();
    injectChatbot();
};

export const openChatbot = (): void => {
    startChatbot();
    localStorage.setItem(CHATBOT_ENABLED, 'true');
};

const closeChatbot = (): void => {
    removeChatbot();
    localStorage.setItem(CHATBOT_ENABLED, 'false');
};

export const initChatbot = (pathname: string, language: string): boolean => {
    if (isHomepage(pathname) && isSidebarOptionAvailable('chatbot', language)) {
        const isChatbotActive = localStorage.getItem(CHATBOT_ENABLED);
        if (!isChatbotActive) {
            localStorage.setItem(CHATBOT_ENABLED, 'true');
        }
        if (!isChatbotActive || isChatbotActive === 'true') {
            if (!getChatbotHtmlElement()) {
                startChatbot();
            }
            return true;
        }
        return false;
    }

    return false;
};
